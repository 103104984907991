import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TimeLines from '../components/TimeLines';
import { makeStyles } from '@material-ui/core/styles';
import Part from '../components/Particles';

const useStyles = makeStyles((theme) => ({
  background: {
    position: 'relative',
    minHeight: '100vh',
  },
  whiteText: {
    marginTop: theme.spacing(5),
    color: 'white',
    position: 'relative',
    zIndex: 1, 
    textAlign: 'center',
  },
  particles: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
}));

const GFG = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          component="h2"
          variant="h2"
          align="center"
          gutterBottom
          className={classes.whiteText}
        >
          Work experiences
        </Typography>
      </Container>
      <div className={classes.particles}>
        <Part />
      </div>
      <Container maxWidth="sm">
        <TimeLines />
      </Container>
    </div>
  );
};

export default GFG;
