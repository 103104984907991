import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import perfilImage from '../assets/front.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    whiteText: {
      color: 'white', // Establece el color del texto a blanco
    },
  }),
);

export default function ImageAvatars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar alt="Femy Sharp" src={perfilImage} className={classes.large} />
      <Typography
        component="h4"
        variant="h4"
        align="center"
        gutterBottom
        className={classes.whiteText} // Aplica la clase de estilo para el color blanco del texto
        style={{ zIndex: 1 }}
      >
        Francisco Javier Valenzuela Palavecino<br />
      Software & ML Engineer
      </Typography>
    </div>
  );
}
