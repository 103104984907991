import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Card, Typography } from '@material-ui/core';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const navigateToExperiences = (payload?: string) => {
    navigate(`/${payload ||'experiences'}`);
  };
  const openWhatsApp = () => {
    // Reemplaza '123456789' con tu número de WhatsApp
    window.open('https://api.whatsapp.com/send?phone=56942349285', '_blank');
  };

  const items = [
    {
      title: 'Timeline experiences',
      description: 'Find out more about my timeline experiences',
      navigate: 'experiences'
    },
    {
      title: 'About me',
      description: 'Find out more about my work experiences',
      file: './fjvp_english_cv.pdf'
    },
    {
      title: 'Contact',
      description: 'Contact me for more information',  
      action: 'whatsapp',
    },
  ];

   
  return (
    <div style={{ width: '80%', margin: '0 auto', paddingTop: '10%' }}>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index}>
            <Card style={{ padding: 20, margin: 20, height: 300, justifyContent: 'center', display: 'flex', flexDirection: 'column'}} onClick={() => item.action ? openWhatsApp() :item.file ? window.open(item.file, '_blank') : navigateToExperiences(item?.navigate) }>
              <Typography variant="h6" component="h2" style={{flex:1}}>
                {item.title}
              </Typography>
              <Typography style={{flex:1}} variant="body1">{item.description}</Typography>
              <Button variant="contained" style={{flex:1, color: '#FFF', backgroundColor: '#000'}}>
              {item.title}
              </Button>

            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
