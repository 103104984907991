export const timelineData = [
    {
      year: '2024 2022',
      color: 'inherit',
      content: 'Walmart Tech Software Engineer',
      description: 'Worked on various projects and contributed to Walmart\'s software development.',
    },
    {
      year: '2021 2022',
      color: 'inherit',
      content: 'INFORSER Software and ML Engineer',
      description: 'Developed software solutions and machine learning applications at INFORSER.',
    },
    {
      year: '2021',
      color: 'inherit',
      content: 'Entel Software and ML Engineer',
      description: 'Played a key role in developing software and machine learning models at Entel.',
    },
    {
      year: '2020',
      color: 'inherit',
      content: 'Andes Salud SW and ML Engineer',
      description: 'Worked on software and machine learning projects at MEDISMART.',
    },
    {
      year: '2019 2020',
      color: 'inherit',
      content: `UBB - Software and Data Engineer`,
      description: 'Contributed to software development and data science projects at UBB.',
    },
    {
      year: '2018 2019',
      color: 'inherit',
      content: 'Banco Estado Software Engineer',
      description: 'Collaborated with the Banco Estado team to create and maintain software solutions.',
    },
    {
      year: '2017 2018',
      color: 'inherit',
      content: 'MEDISMART | Software Engineer',
      description: 'Played a role in software development at MEDISMART during this period.',
    },
  ];
  