import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Paper, Button, Popover, Typography, Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {timelineData} from '../constants/index';

const customTheme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[900],
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      padding: '12px 16px',
    },
  },
  modalPaper: {
    backgroundColor: '#ffffff', // Make the modal more white
    padding: theme.spacing(3),
    textAlign: 'center',
    outline: 'none',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));
const StyledAvatar = styled(Avatar)`
  ${({ theme }: any) => `
  cursor: pointer;
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
  background-color: ${theme.palette.secondary.main};
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};

  &:hover {
    background-color: ${theme.palette.primary.main};
    transform: scale(1.3);
  }

  ${theme.breakpoints.down('sm')} {
    height: ${theme.spacing(10)};
    width: ${theme.spacing(10)};
  }
`}
`;

export default function TransitionHoverTimeline() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState({ content: '', description: '' });

  const handlePopoverOpen = (event: any, item: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentItem(item);
  };

  const handleOpen = (item: any) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentItem({ content: '', description: '' });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    handlePopoverClose();
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Timeline align="alternate" style={{display: 'flex', alignItems: 'center'}}>
        {timelineData.map((item: any, index: number) => (
          <TimelineItem key={item.year}>
            <TimelineSeparator>
              <TimelineDot color={item.color}>
                <StyledAvatar onMouseEnter={(e) => handlePopoverOpen(e, item)}>
                  {item.year}
                </StyledAvatar>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ p: 2 }} className={classes.paper} onClick={() => handleOpen(item)}>
                {item.content}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '200px', // Ajustar el ancho del popover en dispositivos móviles
            [customTheme.breakpoints.down('sm')]: {
              width: '150px',
            },
          },
        }}
      >
        <Button style={{color: 'black' }} onClick={handleModalOpen}>MORE INFO</Button>
      </Popover>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <div className={classes.modalPaper}>
          <Typography variant="h6">{currentItem?.content}</Typography>
          <Typography>{currentItem?.description}</Typography>
          <Button style={{color: 'black' }} onClick={handleModalClose}>Close</Button>
        </div>
      </Modal>
    </ThemeProvider>
  );
}
