import React, { useState } from 'react';
import '../styles/timeline.css';
import Part from '../components/Particles';
import Carousel from '../components/Carousel';
import Avatar from '../components/Avatar';
import {WhatsApp, ContactSupport, LinkedIn} from '@material-ui/icons';

const Home: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=56942349285', '_blank');
  };
  const openLinkedin= () => {
    window.open('https://www.linkedin.com/in/francisco-valenzuela-palavecino-660088188', '_blank');
  };


  return (
    <div>
      <Part />
      <div className="timeline-content">
        <div className="avatarWrapper">
          <div className="avatarCenter">
            <Avatar />
          </div>
        </div>
        <Carousel />
      </div>
      <div className={`floating-button ${isExpanded ? 'expanded' : ''}`}>
        <button className="main-btn" onClick={toggleExpand}>
          <ContactSupport />
        </button>
        <div className="options">
          <button className='wsap' onClick={openWhatsApp}>
            <WhatsApp /> 
          </button>
          <button className='linkedin' onClick={openLinkedin}> 
            <LinkedIn /> 
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
